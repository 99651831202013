import React from "react"
import { graphql } from "gatsby"
import Helmet from 'react-helmet'
import Layout from "../../components/Layout"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { getImage } from "gatsby-plugin-image"

const page = ({ data }) => {
  const { page } = data
  const { content, databaseId, featuredImage } = page
  
  const imageData = getImage(featuredImage?.node?.localFile)
  
  return (
    <Layout>
      <Seo post={page} />
      <Helmet>
        <body className={`page page-id-${databaseId} singular`} style={`background-image: url(${imageData.images.fallback.src})`} />
      </Helmet>
      {/* <Seo title={title} description={excerpt} /> */}

      <article
        className={`post-${databaseId} post page type-page status-publish hentry container`}
        id={`post-${databaseId}`}
      >

        <div className="post-inner thin">
          <div
            className="entry-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query page($id: String!, $nextPage: String, $previousPage: String) {
    page: wpPage(id: {eq: $id}) {
      ...PageContent
      nodeType
      title
      uri
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
    nextPage: wpPage(id: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpPage(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`

export default page;
